import React, { Component } from 'react';
import Helmet from 'react-helmet';
import ReactPlayer from 'react-player';
import { withPrefix } from 'gatsby';

import { FaPlayCircle, FaStopCircle } from 'react-icons/fa';

import Layout from '../components/layout';
import SEO from '../components/seo';

import cdCover from '../images/musique/cd_cover.png';
import danielCinelli from '../images/musique/daniel_cinelli.jpg';
import lamiryanThierry from '../images/musique/lamiryan_thierry.jpg';
import soizicRossier from '../images/musique/soizic_rossier.jpg';
import tedJensen from '../images/musique/ted_jensen.jpg';
import terryNelson from '../images/musique/terry_nelson.jpg';

class MusiquePage extends Component {
  state = {
    url: null,
    playing: true,
  };
  toggle = url => {
    if (url === this.state.url) {
      this.setState({ url: null, playing: false });
    } else {
      this.setState({ url, playing: true });
    }
  };
  renderLoadButton = url => {
    return (
      <button onClick={() => this.toggle(url)} className="btn-player">
        {this.state.url === url ? <FaStopCircle /> : <FaPlayCircle />}
      </button>
    );
  };
  render() {
    const { url, playing } = this.state;

    return (
      <Layout>
        <ReactPlayer width="1" height="1" url={url} playing={playing} />
        <SEO title="Musique" />
        <Helmet bodyAttributes={{ class: 'music' }} />
        <div className="multipageBox p14 p14c8">
          <div className="staticContent" id="staticContent_album">
            <h1>Musique</h1>
            <div id="musicDesc">
              <p>Un style musical incomparable, un c&#339;ur, un feeling</p>
              <p>La puissance d'une voix teint&#233;e d'&#233;motion</p>
              <p>Une signature unique</p>
            </div>
          </div>
        </div>
        <div className="multipageBox p37 p37c0">
          <ul id="musicAlbum">
            <li id="couleur_nature">
              <span className="productionDate">2011</span>
              <h2 className="albumName">Couleur Nature</h2>
              <div className="albumText">
                <p>
                  De par son c&#244;t&#233; nature, Leelo Pascal se fait
                  messager de&#160; valeurs simples. A l&#8217;image de
                  l&#8217;artiste, chaque texte porte un sens profond de
                  r&#233;alisme, o&#249; chacun peut s&#8217;identifier.
                  Percutant et efficace, dans une &#233;criture accessible,
                  l&#8217;album &#171; Couleur Nature &#187; se d&#233;marque
                  par des titres qui gardent une identit&#233; musicale propre.
                  Des chansons aux refrains forts, inoubliables, aux
                  m&#233;lodies intemporelles restent grav&#233;es dans les
                  m&#233;moires d&#232;s la premi&#232;re &#233;coute.
                </p>
              </div>
              <img
                className="albumCover"
                src={cdCover}
                alt="Pochette de l'album Couleur Nature"
                width="180"
                height="200"
              />

              <ol className="albumTracks">
                <li>
                  <span className="songTitle">Le jour se l&egrave;ve</span>
                  {this.renderLoadButton(
                    withPrefix('/mp3/le-jour-se-leve-cut.mp3')
                  )}
                </li>
                <li>
                  <span className="songTitle">Pourquoi</span>
                  {this.renderLoadButton(withPrefix('/mp3/pourquoi-cut.mp3'))}
                </li>
                <li>
                  <span className="songTitle">Mais que restera-t-il</span>
                  {this.renderLoadButton(
                    withPrefix('/mp3/mais-que-restera-t-il-cut.mp3')
                  )}
                </li>
                <li>
                  <span className="songTitle">Malavie</span>
                  {this.renderLoadButton(withPrefix('/mp3/malavie-cut.mp3'))}
                </li>
                <li>
                  <span className="songTitle">Je veux t’aimer</span>
                  {this.renderLoadButton(
                    withPrefix('/mp3/je-veux-t-aimercut.mp3')
                  )}
                </li>
                <li>
                  <span className="songTitle">R&eacute;alise-moi</span>
                  {this.renderLoadButton(
                    withPrefix('/mp3/realise-moi-cut.mp3')
                  )}
                </li>
                <li>
                  <span className="songTitle">Oublier</span>
                </li>
                <li>
                  <span className="songTitle">S’aimer plus fort</span>
                </li>
                <li>
                  <span className="songTitle">J’y crois encore</span>
                </li>
                <li>
                  <span className="songTitle">Revivre pour oublier</span>
                </li>
                <li>
                  <span className="songTitle">Que vous</span>
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div className="multipageBox p14 p14c10">
          <div className="staticContent" id="staticContent_studio">
            <h1>Perfectionnisme, pers&#233;v&#233;rance, performance.</h1>
            <p>
              Jusque dans les moindres d&#233;tails, toujours en qu&#234;te de
              son id&#233;al musical, Leelo Pascal a non seulement choisi les
              meilleurs musiciens et collaborateurs, mais a &#233;galement
              opt&#233; pour les meilleurs studios d&#8217;enregistrements et de
              mastering.
            </p>
            <h2>Studio Plan&#232;te Montr&#233;al</h2>
            <dl className="studioList">
              <dt>
                <span className="imgFrame">
                  <img
                    alt="Daniel Cinelli"
                    height="120"
                    src={danielCinelli}
                    width="180"
                  />
                </span>
              </dt>
              <dd>
                <p>Prises de son finales et mixes: Daniel Cinelli</p>
                <p>
                  R&#233;f&#233;rences: K&#8217;Maro, Roch Voisine, Nolwenn
                  LeRoy, Rihanna, Simple Plan
                </p>
                <p>
                  Un grand merci &#224; Daniel Cinelli et son &#233;quipe pour
                  son professionnalisme incontestable! Leelo Pascal vous invite
                  &#224; aller d&#233;couvrir son studio:{' '}
                  <a href="http://www.planetstudios.ca/">
                    www.planetstudios.ca
                  </a>
                </p>
              </dd>
            </dl>
            <h2>Sterling Sound Studio</h2>
            <dl className="studioList">
              <dt>
                <span className="imgFrame">
                  <img
                    alt="Ted Jensen"
                    height="120"
                    src={tedJensen}
                    width="180"
                  />
                </span>
              </dt>
              <dd>
                <p>Mastering: Ted Jensen</p>
                <p>
                  R&#233;f&#233;rences: Madonna, Coldplay, Superbus, Christina
                  Aguilera, Metallica
                </p>
              </dd>
            </dl>
            <h2>Cible Music Studio</h2>
            <dl className="studioListSmall">
              <dt>
                <span className="imgFrame">
                  <img
                    alt="Soizic Rossier"
                    height="80"
                    src={soizicRossier}
                    width="80"
                  />
                </span>
              </dt>
              <dd>
                <p>Ing&#233;nieur prises de son vocal: Soizic Rossier</p>
              </dd>
              <dt>
                <span className="imgFrame">
                  <img
                    alt="Lamiryan Thierry"
                    height="80"
                    src={lamiryanThierry}
                    width="80"
                  />
                </span>
              </dt>
              <dd>
                <p>Assistante prises de son vocal: Lamiryan Thierry</p>
              </dd>
              <dt>
                <span className="imgFrame">
                  <img
                    alt="Terry Nelson"
                    height="80"
                    src={terryNelson}
                    width="80"
                  />
                </span>
              </dt>
              <dd>
                <p>Conseill&#233;/Assistance Technique: Terry Nelson</p>
              </dd>
            </dl>
          </div>
        </div>
      </Layout>
    );
  }
}

export default MusiquePage;
